.mymodal {
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid rgba(253,29,29,1);
  background: rgb(255,244,0);
  background: linear-gradient(90deg, rgba(255, 174, 0, 0.671) 0%, rgba(252,176,69,1) 22%, rgba(253,29,29,1) 100%);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  min-width: 380px;
  opacity: .9;
  border: 0;
}

.loading-modal {
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid rgba(253,29,29,1);
  background: rgb(255,244,0);
  background: linear-gradient(90deg, rgba(255, 174, 0, 0.671) 0%, rgba(252,176,69,1) 22%, rgba(253,29,29,1) 100%);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  min-width: 420px;
  opacity: .9;
  border: 0;
}

.myoverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.527);
  z-index: 5;
}

.modal-btn{
  z-index: 5;
}
.close{
  width: 100%;
  right: 0;
  top: 0;
  margin-top: -20px;
  margin-right: -20px;
}

.btn--primary{
  background: linear-gradient(250deg, rgb(255, 36, 36) 40%, rgb(255, 224, 27) 97%);
  border: solid 1px   rgb(0, 0, 0) ;
  width: 100%;
  padding: 8px;
  color: white;
  font-weight: 600;
}

.btn--secondary{
  background: #000;
  width: 100%;
  padding: 8px;
  color: white;
  border: 1px solid #000;
  font-weight: 600;
}

.modal-text{
  font-size: 12px;
}
.modal-loading-text{
  font-size: 12px;
}


.lds-dual-ring {
  display: inline-block;
  width: 20px;
  height: 20px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 20px;
  height: 20px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
